import {useRoutes} from "react-router-dom";
import IndexContent from "./IndexContent";
import React from "react";
import PrivateRoute from "../../security/PrivateRoute";
import StorageMuseumContent from "./StorageMuseumContent";
import OthersMuseumContent from "./OthersMuseumContent";
import HCMuseumContent from "./HCMuseumContent";
import CIPMuseumContent from "./CIPMuseumContent";
import AmicMuseumContent from "./AmicMuseumContent";
import JETMuseumContent from "./JETMuseumContent";
import CobraMuseumContent from "./CobraMuseumContent";
import CommodoreMuseumContent from "./CommodoreMuseumContent";
import SpectrumMuseumContent from "./SpectrumMuseumContent";
import ByteMuseumContent from "./ByteMuseumContent";
import DeltaMuseumContent from "./DeltaMuseumContent";
import CableMuseumContent from "./CableMuseumContent";
import JoystickMuseumContent from "./JoystickMuseumContent";
import AlimentatorMuseumContent from "./AlimentatorMuseumContent";
import ModuleMuseumContent from "./ModuleMuseumContent";
import FloppyMuseumContent from "./FloppyMuseumContent";
import CasetofonMuseumContent from "./CasetofonMuseumContent";
import HistoryMuseumContent from "./HistoryMuseumContent";
import FelixBuzunarMuseumContent from "./FelixBuzunarMuseumContent";
import MonitorMuseumContent from "./MonitorMuseumContent";
import BancnoteMuseumContent from "./BancnoteMuseumContent";
import HC2000MuseumContent from "./HC2000MuseumContent";
import HC91MuseumContent from "./HC91MuseumContent";
import CIP03Rosu21MuseumContent from "./CIP03Rosu21MuseumContent";
import CIP03RosuMuseumContent from "./CIP03RosuMuseumContent";
import HC90MuseumContent from "./HC90MuseumContent";
import ProcesorMuseumContent from "./ProcesorMuseumContent";
import CIP01MuseumContent from "./CIP01MuseumContent";
import HC91_24629MuseumContent from "./HC91_24629MuseumContent";
import TastaturaMuseumContent from "./TastaturaMuseumContent";
import AdapterMuseumContent from "./AdapterMuseumContent";
import HC90_835MuseumContent from "./HC90_835MuseumContent";
import Cob2MuseumContent from "./Cob2MuseumContent";
import HC91_912MuseumContent from "./HC91_912MuseumContent";
import CIP01_2MuseumContent from "./CIP01_2MuseumContent";
import CIP03Blue217498MuseumContent from "./CIP03Blue217498MuseumContent";
import CIP03Blue505299MuseumContent from "./CIP03Blue505299MuseumContent";
import CIP03Blue200970MuseumContent from "./CIP03Blue200970MuseumContent";
import CIP03Blue505408MuseumContent from "./CIP03Blue505408MuseumContent";
import HC2000_5954MuseumContent from "./HC2000_5954MuseumContent";
import Daf2020cMuseumContent from "./Daf2020cMuseumContent";


export default function Router() {
    let element = useRoutes([
        {path: "/museum/index", element: <IndexContent/>},
        {path: "/museum/storage", element: <StorageMuseumContent/>},
        {path: "/museum/hc", element: <HCMuseumContent/>},
        {path: "/museum/hc91", element: <HC91MuseumContent/>},
        {path: "/museum/hc91-24629", element: <HC91_24629MuseumContent/>},
        {path: "/museum/hc2000", element: <HC2000MuseumContent/>},
        {path: "/museum/cip", element: <CIPMuseumContent/>},
        {path: "/museum/amic", element: <AmicMuseumContent/>},
        {path: "/museum/jet", element: <JETMuseumContent/>},
        {path: "/museum/cobra", element: <CobraMuseumContent/>},
        {path: "/museum/others", element: <OthersMuseumContent/>},
        {path: "/museum/commodore", element: <CommodoreMuseumContent/>},
        {path: "/museum/spectrum", element: <SpectrumMuseumContent/>},
        {path: "/museum/byte", element: <ByteMuseumContent/>},
        {path: "/museum/delta", element: <DeltaMuseumContent/>},
        {path: "/museum/cable", element: <CableMuseumContent/>},
        {path: "/museum/joystick", element: <JoystickMuseumContent/>},
        {path: "/museum/alimentator", element: <AlimentatorMuseumContent/>},
        {path: "/museum/module", element: <ModuleMuseumContent/>},
        {path: "/museum/floppy", element: <FloppyMuseumContent/>},
        {path: "/museum/casetofon", element: <CasetofonMuseumContent/>},
        {path: "/museum/history", element: <HistoryMuseumContent/>},
        {path: "/museum/felix-buzunar", element: <FelixBuzunarMuseumContent/>},
        {path: "/museum/monitor", element: <MonitorMuseumContent/>},

        {path: "/museum/bancnote", element: <BancnoteMuseumContent/>},
        {path: "/museum/cip01", element: <CIP01MuseumContent/>},
        {path: "/museum/cip01_2", element: <CIP01_2MuseumContent/>},
        {path: "/museum/cip03-217498", element: <CIP03Blue217498MuseumContent/>},
        {path: "/museum/cip03-505299", element: <CIP03Blue505299MuseumContent/>},
        {path: "/museum/cip03-505408", element: <CIP03Blue505408MuseumContent/>},
        {path: "/museum/cip03-200970", element: <CIP03Blue200970MuseumContent/>},
        {path: "/museum/cip03-21", element: <CIP03Rosu21MuseumContent/>},
        {path: "/museum/cip03-rosu", element: <CIP03RosuMuseumContent/>},
        {path: "/museum/hc90", element: <HC90MuseumContent/>},
        {path: "/museum/hc90-835", element: <HC90_835MuseumContent/>},
        {path: "/museum/hc91-912", element: <HC91_912MuseumContent/>},
        {path: "/museum/cob2", element: <Cob2MuseumContent/>},
        {path: "/museum/procesor", element: <ProcesorMuseumContent/>},
        {path: "/museum/keyboard", element: <TastaturaMuseumContent/>},

        {path: "/museum/util/adapter", element: <AdapterMuseumContent/>},
        {path: "/museum/hc2000-5954", element: <HC2000_5954MuseumContent/>},
        {path: "/museum/daf2020c", element: <Daf2020cMuseumContent/>},

    ]);

    return element;
}