import React from "react";

import TitleFragment from "../TitleFragment";
import MuseumToc from "./MuseumToc";
import NavigatorFragment from "../NavigatorFragment";

function HC2000_5954MuseumContent(props) {

    let toc = new MuseumToc();

    let pageId = "museum-hc2000-5954";

    return (
        <div className="home boltzmann">

            <TitleFragment pageId={pageId} toc={toc.getContent()}/>
            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

            <div className={"text-justify important"}>


                <b>HC 2000: Serie No / Part No: 5954</b>
                <br/>
                <br/>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_46.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_42.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_43.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_44.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_45.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_47.png'}/>
                </div>

                Stare: <b>Nefunctional</b>

                <br/>

                Sursa alimentare HC 2000:

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_48.png'}/>
                </div>
                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_49.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_50.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_51.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_52.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_53.png'}/>
                </div>

            </div>

            <div className={"text-justify"}>


            </div>

            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

        </div>
    );
}

export default HC2000_5954MuseumContent;