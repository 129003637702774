import React from "react";

import TitleFragment from "../TitleFragment";
import MuseumToc from "./MuseumToc";
import NavigatorFragment from "../NavigatorFragment";

function HCMuseumContent(props) {

    let toc = new MuseumToc();

    let pageId = "museum-hc";

    return (
        <div className="home boltzmann">

            <TitleFragment pageId={pageId} toc={toc.getContent()}/>
            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

            <div className={"text-justify important"}>

                Calculatoare
                <ul>
                    <li>HC 85</li>
                    <li>HC 88</li>
                    <li>HC 90</li>
                    <li>HC 91</li>
                    <li>HC 91+</li>
                    <li>HC 2000</li>
                    <li>HC 386</li>
                </ul>
                <hr/>

                <b>HC 85</b>
                <br/>
                <br/>

                Detalii:
                <ul>
                    <li>
                        An producție: 1985
                    </li>
                    <li>
                        Producător: I.C.E. Felix București
                    </li>
                    <li>
                        Model standard: 40 de taste în două variante:
                        <ul>
                            <li>
                                cutia fabricată din <b>ebonită</b> (primele modele)
                            </li>
                            <li>
                                cutia fabricată din <b>plastic</b>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Microprocesor: Z80A
                    </li>
                    <li>
                        Memoria EPROM: 16 KB
                    </li>
                    <li>
                        Memoria RAM: 48 KB
                    </li>
                    <li>
                        Sistem de operare: BASIC 48K (Spectrum Basic interpreter)
                    </li>
                    <li>
                        Rezoluție: 256 X 192 pixeli, 256 X 176 în mod BASIC
                    </li>
                    <li>
                        Mod text: 32 coloane X 24 linii; liniile 23 și 24 utilizate pentru comenzi în mod BASIC, având posibilitatea de extindere
                    </li>
                    <li>
                        Număr de culori disponibile: 16 culori
                    </li>
                    <li>
                        Sunet: difuzor încorporat care poate emite sunete în intervalul de 10 octave
                    </li>
                    <li>
                        Dimensiuni: 34 (lățime) X 25 (lungime) X 4 (înălțime) cm
                    </li>
                    <li>
                        Extensie: 198x - Interfața 1 pentru HC-85
                    </li>
                </ul>

                Conectori:
                <ul>
                    <li>
                        Conectorul de alimentare este folosit la alimentarea calculatorului de la alimentatorul de +9V.
                        (puterea consumata de calculator este de aprox. 15 W)
                    </li>
                    <li>
                        Conectorul de televizor este folosit pentru interconectarea cu un televizor alb-negru sau color de tip PAL.
                    </li>
                    <li>
                        Conectorul video permite conectarea la un monitor alb-negru sau color (PAL~ RGB)
                    </li>
                    <li>
                        Conectorii de joystick permit cuplarea a doua joystick-uri de tip Sinclair
                    </li>
                    <li>
                        Conectorul pentru extensie (edge-conectorul) foloseste la legarea extensiilor (disc, interfata seriala, PROM, etc.).
                    </li>
                    <li>
                        Conectorul de casetofon este folosit pentru incarcarea programelor de pe caseta ai salvarea lor pe caseta.
                        Se recomanda folosirea casetelor de tip C-60.
                        Casetofonul folosit poate fi orice tip de casetofon audio.
                        Se poate folosi de asemenea orice tip de magnetofon.
                    </li>
                </ul>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_1.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_2.png'}/>
                </div>

                Manual:
                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_3.png'}/>
                </div>

                Link vizualizare manual PDF: <a target={"_blank"} href={"https://www.z80-romania.ro/1987_Manual_tehnic_HC_85-55"}>Manual PDF</a>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900, borderColor: "yellowgreen", borderWidth: 2, borderStyle: "solid"}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_14.png'}/>
                </div>


                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900, borderColor: "yellowgreen", borderWidth: 2, borderStyle: "solid"}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_108.png'}/>
                </div>
                (sursa: <a href={"https://retroit.ro/"} target={"_blank"}>retroit.ro</a> )

                <hr/>
                <b>HC 88</b>
                <br/>
                <br/>

                Detalii:
                <ul>
                    <li>
                        An producție: 1990
                    </li>
                    <li>
                        Producător: I.C.E. Felix București
                    </li>
                    <li>
                        Model standard: 40 de taste
                    </li>
                    <li>
                        Microprocesor: Z80A (Zilog)
                    </li>
                    <li>
                        Memoria EPROM: 16 KB
                    </li>

                    <li>
                        Sistem de operare: BASIC 48K (Spectrum Basic interpreter)
                    </li>
                    <li>
                        Rezoluție: 256 X 192 pixeli, 256 X 176 în mod BASIC
                    </li>
                    <li>
                        Mod text: 32 coloane X 24 linii; liniile 23 și 24 utilizate pentru comenzi în mod BASIC, având posibilitatea de extindere
                    </li>
                    <li>
                        Număr de culori disponibile: 16 culori
                    </li>
                    <li>
                        Sunet: difuzor încorporat care poate emite sunete în intervalul de 10 octave
                    </li>
                    <li>
                        Dimensiuni: 34 (lățime) X 25 (lungime) X 4 (înălțime) cm
                    </li>
                    <li>
                        Interfață joystick încorporată: Sinclair 1 și Sinclair 2
                    </li>

                </ul>

                <hr/>
                <b>HC 90</b>
                <br/>
                <br/>
                Calculatorul personal HC 90 a fost produs incepand cu anul 1990 la intreprinderea ICE Felix Bucuresti. Productia a continuat pana in anul 1992.
                <br/>
                Detalii:
                <ul>
                    <li>
                        Model standard: 40 de taste
                    </li>
                    <li>
                        Microprocesor: Z80A avand viteza de 3,5 Mhz
                    </li>
                    <li>
                        Memoria ROM: 16 kB
                    </li>
                    <li>
                        Memoria RAM: 64 kB (din care, 48 erau disponibili pt utilizator)
                    </li>
                    <li>
                        Sistem de operare: BASIC 48K (Spectrum Basic interpreter)
                    </li>
                    <li>
                        Rezoluție: 256 X 192 pixeli, 256 X 176 în mod BASIC
                    </li>
                    <li>
                        Mod text: 32 coloane X 24 linii; liniile 23 și 24 utilizate pentru comenzi în mod BASIC, având posibilitatea de extindere
                    </li>
                    <li>
                        Număr de culori disponibile: 16 culori
                    </li>
                    <li>
                        Sunet: difuzor încorporat care poate emite sunete în intervalul de 10 octave
                    </li>
                    <li>
                        Dimensiuni: 34 (lățime) X 25 (lungime) X 4 (înălțime) cm
                    </li>
                    <li>
                        Interfață joystick încorporată: Sinclair 1 și Sinclair 2
                    </li>
                </ul>

                Porturi:
                <ul>
                    <li>
                        TV/RF (RCA-F)
                        <br/>
                        Conectorul TV - mufa RCA mama - serveste la conectarea televizorului prin intermediul mufei de antena. Canalul 8.
                    </li>
                    <li>
                        RGB (DB9-F)
                        <li>
                            Conector VIDEO:
                            <ul>
                                <li> 1.SHVL</li>
                                <li> 2.MASA (GND)</li>
                                <li> 3.R (rosu)</li>
                                <li> 4.G (verde)</li>
                                <li> 5.B (albastru)</li>
                                <li> 6.BRGH. (intensitate)</li>
                                <li> 7.VIDEOOUT</li>
                                <li> 8.Hsync.</li>
                                <li> 9.Vsync.</li>
                            </ul>
                        </li>
                        Interfata (seriala) standard RS-232 permite conectarea unui HC-90 cu alt calculator sau alte periferice inzestrate cu aceasta interfata.
                        <br/>
                        Utilizarea se realizeaza folosind cuvintele cheie OPEN#, CLOSE#, MOVE, :RASE, CAT si FORMAT
                    </li>
                    <li>
                        intefata casetofon
                    </li>
                    <li>
                        extensie
                    </li>
                    <li>
                        alimentare ( mufa rotunda / jack - 9V stabilizati)
                    </li>
                </ul>


                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_18.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_19.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-3/img_50.png'}/>
                </div>


                HC 90 + IF1:
                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_17.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_43.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-5/img_17.png'}/>
                </div>


                <hr/>
                <b>
                    HC 91
                </b>
                <br/>
                <br/>
                Calculatorul personal HC 91 a fost produs de I.C.E. Felix incepand cu 1991.
                <br/>

                Detalii:
                <ul>
                    <li>
                        Model standard: 40 de taste
                    </li>
                    <li>
                        Microprocesor: Z80A avand viteza de 3,5 Mhz
                    </li>
                    <li>
                        Memoria ROM: 16 kB
                    </li>
                    <li>
                        Memoria RAM: 64 kB (din care, 48 erau disponibili pt utilizator)
                    </li>
                    <li>
                        Sistem de operare: BASIC 48K (Spectrum Basic interpreter)
                    </li>
                    <li>
                        Rezoluție: 256 X 192 pixeli, 256 X 176 în mod BASIC
                    </li>
                    <li>
                        Mod text: 32 coloane X 24 linii; liniile 23 și 24 utilizate pentru comenzi în mod BASIC, având posibilitatea de extindere
                    </li>
                    <li>
                        Număr de culori disponibile: 16 culori
                    </li>
                    <li>
                        Sunet: difuzor încorporat care poate emite sunete în intervalul de 10 octave
                    </li>
                    <li>
                        Dimensiuni: 34 (lățime) X 25 (lungime) X 4 (înălțime) cm
                    </li>
                </ul>
                Porturi:
                <ul>
                    <li>
                        TV/RF (RCA-F)
                    </li>
                    <li>
                        RGB (DB9-F)
                    </li>
                    <li>
                        Joystick (DB9-M)
                    </li>
                    <li>
                        Slot extensie compatibil ZX Spectrum
                    </li>
                    <li>
                        Conector casetofon (DIN-5)
                    </li>
                    <li>
                        Conector alimentare
                    </li>
                </ul>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_25.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_26.png'}/>
                </div>

                Calculatorul a fost vandut cu 2 variante de tastatura:
                <ul>
                    <li>40 de taste, preluata de la modele precedente (varianta cea mai raspandita)</li>
                    <li>
                        50 de taste, preluata si de HC91+
                        <br/>
                        Tastatura extinsa avea urmatoarele taste:
                        <ul>
                            <li>
                                EDIT
                            </li>
                            <li>
                                TAB
                            </li>
                            <li>
                                CAPS LOCK (CL)
                            </li>
                            <li>
                                CAPS SHIFT (CS)
                            </li>
                            <li>
                                EXT MODE
                            </li>
                            <li>
                                DELETE
                            </li>
                            <li>
                                RETURM
                            </li>
                            <li>
                                GRAPH
                            </li>
                            <li>
                                SYMBOL SHIFT (SS)
                            </li>
                            <li>
                                sagetile: SUS, JOS, DREAPTA, STANGA
                            </li>
                        </ul>
                    </li>
                </ul>

                <hr/>
                <b>HC 91+</b>
                <br/>
                <br/>
                HC 91+ a fost produs in anul 1994 de catre I.C.E. Felix.
                <br/>
                Detalii:
                <ul>
                    <li>
                        Model standard: 51 de taste
                    </li>
                    <li>
                        Microprocesor: Z80
                    </li>
                    <li>
                        Memoria ROM: 32 kB
                    </li>
                    <li>
                        Memoria RAM: 128 kB (din care, 48 erau disponibili pt utilizator)
                    </li>
                    <li>
                        Sistem de operare: BASIC 48K/BASIC 128K (Spectrum Basic interpreter)
                    </li>
                    <li>
                        Rezoluție: 256 X 192 pixeli, 256 X 176 în mod BASIC
                    </li>
                    <li>
                        Mod text:
                        <ul>
                            <li>32 coloane X 24 linii; liniile 23 și 24 utilizate pentru comenzi în mod BASIC, având posibilitatea de extindere</li>
                            <li>80 coloane X 25 linii (mod CP/M)</li>
                        </ul>
                    </li>
                    <li>
                        Număr de culori disponibile: 16 culori
                    </li>
                    <li>
                        Sunet: difuzor încorporat care poate emite sunete în intervalul de 10 octave
                    </li>
                    <li>
                        Dimensiuni: 34 (lățime) X 25 (lungime) X 6 (înălțime) cm
                    </li>
                </ul>
                Porturi:
                <ul>
                    <li>
                        TV/RF (RCA-F)
                    </li>
                    <li>
                        RGB (DB9-F)
                    </li>
                    <li>
                        Joystick (DB9-M)
                    </li>
                    <li>
                        Slot extensie compatibil ZX Spectrum
                    </li>
                    <li>
                        Conector casetofon (DIN-5)
                    </li>
                    <li>
                        Conector alimentare
                    </li>
                </ul>


                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_23.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_22.png'}/>
                </div>

                <hr/>
                <b>HC 2000</b>
                <br/>
                <br/>
                Detalii:
                <ul>
                    <li>
                        Model standard: 51 de taste
                    </li>
                    <li>
                        Microprocesor: Z80
                    </li>
                    <li>
                        Memoria EPROM: 16 KB (BASIC), 8K (CP/M BIOS), 8K (interfață disc)
                    </li>
                    <li>
                        Memoria RAM: 64 KB (memorii 41464), disponibil 48 KB
                    </li>
                    <li>
                        Sistem de operare: BASIC 48K (Spectrum Basic interpreter), CP/M (accesibil de pe disc flexibil)
                    </li>
                    <li>
                        Rezoluție: 256 X 192 pixeli, 256 X 176 în mod BASIC
                    </li>
                    <li>
                        Mod text:
                        <ul>
                            <li>32 coloane X 24 linii; liniile 23 și 24 utilizate pentru comenzi în mod BASIC, având posibilitatea de extindere</li>
                            <li>80 coloane X 25 linii (mod CP/M)</li>
                        </ul>
                    </li>
                    <li>
                        Număr de culori disponibile: 16 culori
                    </li>
                    <li>
                        Sunet: difuzor încorporat care poate emite sunete în intervalul de 10 octave
                    </li>
                    <li>
                        Dimensiuni: 50 (lățime) X 20 (lungime) X 6 (înălțime) cm
                    </li>
                </ul>
                Porturi:
                <ul>
                    <li>
                        TV/RF (RCA-F)
                    </li>
                    <li>
                        RGB (DB9-F / 9-pini mamă D-Sub DE-9)
                    </li>
                    <li>
                        Joystick (DB9-M / 9-pini tată D-Sub DE-9)
                    </li>
                    <li>
                        Slot extensie compatibil ZX Spectrum
                    </li>
                    <li>
                        Conector casetofon (DIN-5 / conector tata 5 pini)
                    </li>
                    <li>
                        RS-232 (DB9-F)
                    </li>
                    <li>
                        Conector pentru unitate disc flexibil externă (opțional)
                    </li>
                    <li>
                        Unitate disc flexibil 3.5 inch internă
                    </li>
                </ul>
                Alimentare: externă, +5V (pentru circuitele TTL), +12V și -12V (pentru interfața serial)

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_4.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_12.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_41.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_42.png'}/>
                </div>


                A exista si o varianta cu casetofon:

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900, borderColor: "yellowgreen", borderWidth: 2, borderStyle: "solid"}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_16.png'}/>
                </div>

                <hr/>
                <b>HC 386</b>
                <br/>
                <br/>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900, borderColor: "yellowgreen", borderWidth: 2, borderStyle: "solid"}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_13.png'}/>
                </div>

                <hr/>
                <b>Fabricate <i>clandestin</i></b>
                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_20.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_21.png'}/>
                </div>

            </div>

            <div className={"text-justify"}>


            </div>

            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

        </div>
    );
}

export default HCMuseumContent;