import React from "react";

import TitleFragment from "../TitleFragment";
import MuseumToc from "./MuseumToc";
import NavigatorFragment from "../NavigatorFragment";

function StorageMuseumContent(props) {

    let toc = new MuseumToc();

    return (
        <div className="home boltzmann">

            <TitleFragment pageId={"museum-storage"} toc={toc.getContent()}/>
            <NavigatorFragment pageId={"museum-storage"} toc={toc.getContent()}/>

            <div className={"text-justify important"}>
                Medii de stocare:
                <ul>
                    <li>
                        <b>cartele</b>

                        <div style={{padding: 10}}>
                            <img alt={""} style={{maxWidth: 700, width: "100%"}} className={"rounded mx-auto d-block responsive-img"}
                                 src={process.env.PUBLIC_URL + '/img/museum-3/img_38.png'}/>
                        </div>

                        <div className={"row"}>
                            <div style={{padding: 10}}>
                                <img alt={""} style={{maxWidth: 500, width: "100%"}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/museum-3/img_39.png'}/>
                            </div>

                            <div style={{padding: 10}}>
                                <img alt={""} style={{maxWidth: 500, width: "100%"}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/museum-3/img_40.png'}/>
                            </div>

                        </div>

                        <div className={"row"}>
                            <div style={{padding: 10}}>
                                <img alt={""} style={{maxWidth: 500, width: "100%"}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/museum-3/img_41.png'}/>
                            </div>

                            <div style={{padding: 10}}>
                                <img alt={""} style={{maxWidth: 500, width: "100%"}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/museum-3/img_42.png'}/>
                            </div>
                        </div>

                    </li>
                    <li>
                        <b>dischete</b>
                        <div className={"row center"}>
                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 150}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/museum/floppy2s.jpg'}/>
                            </div>
                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 150}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/museum/floppy-s.jpeg'}/>
                            </div>
                        </div>
                    </li>
                    <li>
                        <b>role</b>
                    </li>
                    <li>
                        <b>casete</b>
                    </li>
                </ul>
                <hr/>
                Detalii legate de mediile de stocare:
                <ul>
                    <li>
                        cartelă perforată
                        <ul>
                            Începând cu 1920, cartelele perforate au devenit standard pentru stocarea datelor în sistemele de calcul mecanice și ulterior electronice.
                        </ul>
                        <ul>
                            Originea:
                            <ul>
                                <li>
                                    Invenția cartelelor perforate este atribuită lui Joseph-Marie Jacquard în 1801, pentru a controla războaiele de țesut.
                                </li>
                                <li>
                                    În 1890, Herman Hollerith a adaptat conceptul pentru a procesa datele recensământului din SUA, creând baza pentru compania IBM.
                                </li>
                            </ul>
                        </ul>
                    </li>
                    <li>
                        dischete (floppy) 8 inch
                        <ul>
                            <ul>Introduse: În anii 1970</ul>
                            <ul>Capacitate: Aproximativ 80 KB - 1 MB</ul>
                        </ul>

                        <div className={"row center"}>
                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/museum-2/img_2.png'}/>
                            </div>

                            {/*<div style={{padding: 10}}>*/}
                            {/*    <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}*/}
                            {/*         src={process.env.PUBLIC_URL + '/img/museum-2/img_3.png'}/>*/}
                            {/*</div>*/}

                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 450}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/museum-2/img_4.png'}/>
                            </div>
                        </div>
                    </li>

                    <li>
                        dischete (floppy) 5 inch
                        <ul>
                            <ul>Introduse: În 1976, popularizate în anii 1980</ul>
                            <ul>
                                Capacitate:
                                <ul>
                                    <li>
                                        Single-sided, single density: ~90-160 KB
                                    </li>
                                    <li>
                                        Single-sided, double density: ~320 KB
                                    </li>
                                    <li>
                                        Double-sided, double density: ~360-1.2 MB;
                                        <div className={"row"}>
                                            <div style={{padding: 10}}>
                                                <img alt={""} style={{width: 300}} className={"rounded mx-auto d-block responsive-img"}
                                                     src={process.env.PUBLIC_URL + '/img/museum/f360s.jpeg'}/>
                                            </div>

                                            <div style={{padding: 10}}>
                                                <img alt={""} style={{width: 300}} className={"rounded mx-auto d-block responsive-img"}
                                                     src={process.env.PUBLIC_URL + '/img/museum/fv360s.jpeg'}/>
                                            </div>

                                            <div style={{padding: 10}}>
                                                <img alt={""} style={{width: 300}} className={"rounded mx-auto d-block responsive-img"}
                                                     src={process.env.PUBLIC_URL + '/img/museum/faa360s.jpeg'}/>
                                            </div>

                                            <div style={{padding: 10}}>
                                                <img alt={""} style={{width: 300}} className={"rounded mx-auto d-block responsive-img"}
                                                     src={process.env.PUBLIC_URL + '/img/museum/floppyblues.jpeg'}/>
                                            </div>

                                            <div style={{padding: 10}}>
                                                <img alt={""} style={{width: 300}} className={"rounded mx-auto d-block responsive-img"}
                                                     src={process.env.PUBLIC_URL + '/img/museum/floppyreds.jpeg'}/>
                                            </div>

                                            <div style={{padding: 10}}>
                                                <img alt={""} style={{width: 300}} className={"rounded mx-auto d-block responsive-img"}
                                                     src={process.env.PUBLIC_URL + '/img/museum/fa360s.jpeg'}/>
                                            </div>

                                            <div style={{padding: 10}}>
                                                <img alt={""} style={{width: 300}} className={"rounded mx-auto d-block responsive-img"}
                                                     src={process.env.PUBLIC_URL + '/img/museum/floppyblacks.jpeg'}/>
                                            </div>

                                        </div>

                                        cu protectia din hartie:
                                        <div style={{padding: 10}}>
                                            <img alt={""} style={{width: 300}} className={"rounded mx-auto d-block responsive-img"}
                                                 src={process.env.PUBLIC_URL + '/img/museum/covers.jpeg'}/>
                                        </div>
                                    </li>
                                    <li>
                                        Double-Sided, High-Density (DSHD): ~1.2 MB
                                        <div style={{padding: 10}}>
                                            <img alt={""} style={{width: 300}} className={"rounded mx-auto d-block responsive-img"}
                                                 src={process.env.PUBLIC_URL + '/img/museum/floppy12s.jpeg'}/>
                                        </div>
                                        au existat de si de 1.6MB, dar fost mai degrabă o excepție decât regula, iar formatul de 1.2 MB a rămas cel mai comun pentru acest tip de
                                        discuri:
                                        <div style={{padding: 10}}>
                                            <img alt={""} style={{width: 300}} className={"rounded mx-auto d-block responsive-img"}
                                                 src={process.env.PUBLIC_URL + '/img/museum/floppy5s.jpeg'}/>
                                        </div>
                                    </li>
                                </ul>
                                (numar exemplare: 128 )
                            </ul>

                        </ul>
                    </li>

                    <li>
                        dischete (floppy) 3.5 inch
                        <ul>
                            <li>
                                Introduse: În 1982, popularizate în anii 1990.
                            </li>
                            <ul>
                                Capacitate:
                                <ul>
                                    <li>
                                        Single-sided, double density: ~720 KB
                                    </li>
                                    <li>
                                        Double-sided, high density (HD): ~1.44 MB (cel mai comun format).
                                    </li>
                                    <li>
                                        Extra-high density (ED): ~2.88 MB (mai puțin comun).
                                    </li>
                                </ul>
                            </ul>
                        </ul>

                        <div className={"row center"}>
                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/museum-2/img_8.png'}/>
                            </div>

                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 410}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/museum-2/img_9.png'}/>
                            </div>

                        </div>

                        <div className={"row center"}>
                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 410}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/museum-2/img_5.png'}/>
                            </div>

                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/museum-2/img_6.png'}/>
                            </div>

                        </div>

                        <div className={"row center"}>
                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/museum-2/img_7.png'}/>
                            </div>

                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/museum-2/img_10.png'}/>
                            </div>

                        </div>

                    </li>
                    <li>
                        casete:
                        <ul>
                            <li>
                                Totul despre microprocesorul Z80:
                                <div className={"row center"}>
                                    <div style={{padding: 10}}>
                                        <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/museum/caseta2cs.jpeg'}/>
                                    </div>
                                    <div style={{padding: 10}}>
                                        <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/museum/caseta2s.jpeg'}/>
                                    </div>
                                </div>
                            </li>

                            <li>
                                Jocuri pe calculator:
                                <div className={"row center"}>
                                    <div style={{padding: 10}}>
                                        <img alt={""} style={{width: 200}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/museum/caseta3cs.jpeg'}/>
                                    </div>
                                </div>
                            </li>

                            <li>
                                Jocuri pe calculator:
                                <div className={"row center"}>
                                    <div style={{padding: 10}}>
                                        <img alt={""} style={{width: 250}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/museum-2/img_18.png'}/>
                                    </div>
                                    <div style={{padding: 10}}>
                                        <img alt={""} style={{width: 450}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/museum-2/img_17.png'}/>
                                    </div>
                                </div>
                            </li>

                            <li>
                                Jocuri pe calculator:
                                <div className={"row center"}>
                                    <div style={{padding: 10}}>
                                        <img alt={""} style={{width: 250}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/museum-2/img_21.png'}/>
                                    </div>
                                    <div style={{padding: 10}}>
                                        <img alt={""} style={{width: 250}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/museum-2/img_20.png'}/>
                                    </div>

                                    <div style={{padding: 10}}>
                                        <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/museum-2/img_19.png'}/>
                                    </div>
                                </div>
                            </li>


                            <li>
                                StarWars - Return of the Jedi (Death star battle):
                                <div className={"row center"}>
                                    <div style={{padding: 10}}>
                                        <img alt={""} style={{width: 200}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/museum/caseta4cs.jpeg'}/>
                                    </div>
                                    <div style={{padding: 10}}>
                                        <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/museum/caseta4s.jpeg'}/>
                                    </div>
                                </div>
                            </li>

                            <li>
                                Raid over Moscow:
                                <div className={"row center"}>
                                    <div style={{padding: 10}}>
                                        <img alt={""} style={{width: 200}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/museum/caseta1ca.jpeg'}/>
                                    </div>
                                    <div style={{padding: 10}}>
                                        <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/museum/caseta1s.jpeg'}/>
                                    </div>
                                </div>
                            </li>

                            <li>
                                Backgammon:
                                <div className={"row center"}>
                                    <div style={{padding: 10}}>
                                        <img alt={""} style={{width: 200}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/museum/caseta5cs.jpeg'}/>
                                    </div>
                                    <div style={{padding: 10}}>
                                        <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/museum/caseta5s.jpeg'}/>
                                    </div>
                                </div>
                            </li>

                            <li>
                                Reversi:
                                <div className={"row center"}>
                                    <div style={{padding: 10}}>
                                        <img alt={""} style={{width: 250}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/museum-2/img_11.png'}/>
                                    </div>
                                    <div style={{padding: 10}}>
                                        <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/museum-2/img_12.png'}/>
                                    </div>

                                </div>
                                    <div className={"row center"}>
                                        <div style={{padding: 10}}>
                                            <img alt={""} style={{height: 200}} className={"rounded mx-auto d-block responsive-img"}
                                                 src={process.env.PUBLIC_URL + '/img/museum-5/img_41.png'}/>
                                        </div>

                                        <div style={{padding: 10}}>
                                            <img alt={""} style={{height: 200}} className={"rounded mx-auto d-block responsive-img"}
                                                 src={process.env.PUBLIC_URL + '/img/museum-5/img_40.png'}/>
                                        </div>

                                        <div style={{padding: 10}}>
                                            <img alt={""} style={{height: 200}} className={"rounded mx-auto d-block responsive-img"}
                                                 src={process.env.PUBLIC_URL + '/img/museum-5/img_48.png'}/>
                                        </div>
                                    </div>
                            </li>

                            <li>
                                Horizons:
                                <div className={"row center"}>
                                    <div style={{padding: 10}}>
                                        <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/museum-2/img_13.png'}/>
                                    </div>
                                    <div style={{padding: 10}}>
                                        <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/museum-2/img_14.png'}/>
                                    </div>
                                </div>
                            </li>

                            <li>
                                Timebomb:
                                <div className={"row center"}>
                                    <div style={{padding: 10}}>
                                        <img alt={""} style={{width: 350}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/museum-2/img_15.png'}/>
                                    </div>
                                    <div style={{padding: 10}}>
                                        <img alt={""} style={{width: 350}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/museum-2/img_16.png'}/>
                                    </div>
                                </div>
                            </li>

                            <li>
                                The Sports Pack:
                                <div style={{padding: 10}}>
                                    <img alt={""} style={{width: 300}} className={"rounded mx-auto d-block responsive-img"}
                                         src={process.env.PUBLIC_URL + '/img/museum-5/img_42.png'}/>
                                </div>
                                <div className={"row center"}>

                                    1. Snooker:
                                    <div style={{padding: 10}}>
                                        <img alt={""} style={{height: 300}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/museum-5/img_47.png'}/>
                                    </div>

                                    <div style={{padding: 10}}>
                                        <img alt={""} style={{height: 300}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/museum-5/img_46.png'}/>
                                    </div>
                                </div>
                                <div className={"row center"}>
                                    2. World Cup

                                    <div style={{padding: 10}}>
                                        <img alt={""} style={{height: 200}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/museum-5/img_45.png'}/>
                                    </div>

                                    <div style={{padding: 10}}>
                                        <img alt={""} style={{height: 200}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/museum-5/img_44.png'}/>
                                    </div>

                                    <div style={{padding: 10}}>
                                        <img alt={""} style={{height: 200}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/museum-5/img_43.png'}/>
                                    </div>

                                </div>
                            </li>

                        </ul>

                    </li>
                </ul>

            </div>

            <div className={"text-justify"}>


            </div>

            <NavigatorFragment pageId={"museum-storage"} toc={toc.getContent()}/>

        </div>
    );
}

export default StorageMuseumContent;