import React from "react";

import TitleFragment from "../TitleFragment";
import MuseumToc from "./MuseumToc";
import NavigatorFragment from "../NavigatorFragment";

function Daf2020cMuseumContent(props) {

    let toc = new MuseumToc();

    let pageId = "museum-daf2020c";

    return (
        <div className="home boltzmann">

            <TitleFragment pageId={pageId} toc={toc.getContent()}/>
            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

            <div className={"text-justify important"}>

                <b>DAF 2020C</b> (<b>D</b>isplay grafic si <b>a</b>l<b>f</b>anumeric <b>c</b>olor) este un terminal romanesc fabricat de <b>FAPER</b>, Bucuresti.
                <br/>
                Configuratie:
                <ul>
                    <li>an: ~1986-1987</li>
                    <li>
                        procesor: Z80
                    </li>
                    <li>
                        memorie ROM: 8 KB
                    </li>
                    <li>
                        memorie RAM: 1 KB
                    </li>
                    <li>memorie de ecran - 64 KB, organizata in 4 planuri</li>
                    <li>
                        rezoluție: 448 x 288 pucte
                    </li>
                    <li>intefete:
                        <ul>
                            standard V24 (RS 232C) - serial asicron (25 pini)
                        </ul>
                    </li>
                    <li>
                        tastatura (25 pini):
                        <ul>
                            de tip paralel cu maximum 2 taste pasate simultan si informatie codata ASCII
                        </ul>
                        <li>
                            numar taste: 82
                        </li>
                    </li>
                    <li>
                        monitorul:
                        <ul>
                            <li>
                                se poate folosi orice monitor cu rezolutie minima de 450 triada si intrari de R,G,B si sicronizare
                            </li>
                            <li>
                                diagonale: 31 cm, 47 cm, 59cm (Electronica 001) si 67 (Electronica 011)
                            </li>
                        </ul>
                    </li>
                </ul>

                Se folosea cu monitorul 001 Fabricat la Electronica.

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-5/img_32.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-5/img_33.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-5/img_34.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-5/img_35.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-5/img_36.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-5/img_37.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-5/img_38.png'}/>
                </div>

                Alte informatii:

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-5/img_39.png'}/>
                </div>

                Alte link-uri:
                <ul>
                    <li>
                        <a target={"_blank"}
                           href={"https://exporomania.ro/exponate/articol/reportaj/periferice-grafice-romanesti/"}>https://exporomania.ro/exponate/articol/reportaj/periferice-grafice-romanesti/</a>
                    </li>
                </ul>
            </div>

            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

        </div>
    );
}

export default Daf2020cMuseumContent;